import { Pipe, PipeTransform, inject } from '@angular/core';
import moment from 'moment';
import { DateProxyPipe } from './pipe/date-proxy.pipe';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateOrDateTime',
  standalone: true,
})
export class DateOrDateTimePipe implements PipeTransform {
  private dateTimePipe = new DateProxyPipe(inject(TranslateService));
  transform(val: string): string {
    const day = moment(val).utc(false).format('YYYY-MM-DD');
    const time = moment(val).utc(false).format('HH:mm:ss');

    // check if the time is at the start or end of the day
    const fullDay =
      moment(day).startOf('day').format('HH:mm:ss') === time ||
      moment(day).endOf('day').format('HH:mm:ss') === time;
    // if the time is at the start or end of the day, return just the date else return the date and time
    return fullDay
      ? (this.dateTimePipe.transform(val, 'shortDate') ?? '')
      : (this.dateTimePipe.transform(val, 'short') ?? '');
  }
}
